@import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '../node_modules/react-datasheet/lib/react-datasheet.css';
@import '../node_modules/react-sortable-tree/style.css';

:root {
    /* define global css vars here  */
    --site-max-width: 1024px;
}

* {
    box-sizing: border-box;
}

body {
}

.calendar {
    height: 200px;
}

.chart {
    height: 300px;
}

/* Utilities CSS classes supporting Semantic UI */
.version--item {
    td {
        p {
            font-size: 14px;
            margin: 0px;
        }
    }
}

.noMargin {
    margin: 0px !important;
}

.noPadding {
    padding: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}


.mb-5 {
    margin-bottom: 5px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.noVerticalPadding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.noHorizontalPadding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.noBorders {
    border: none !important;
    box-shadow: none !important;
}

.border-red {
    border-color: red !important;
}

.readOnlyField {
    margin-bottom: 0px !important;
    input {
        border-width: 0px 0px 1px 0px !important;
        margin-bottom: 0px !important;
    }
}

.my-2 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.py-14 {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.px-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
}

.px-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

.px-9 {
  padding-right: 9px !important;
  padding-left: 9px !important;
}

.py-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-5 {
    padding: 5px !important;
}

.min-h-22 {
    min-height: 22px !important;
}

.min-h-350 {
    min-height: 350px !important;
}

.w-70 {
  width: 70px !important;
}

.w-200 {
  width: 200px !important;
}

.w-600 {
  width: 600px !important;
}

.min-w-200 {
  min-width: 200px;
}

.mt-6 {
    margin-top: 6px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-28 {
    margin-left: 28px !important;
}

.mr-28 {
    margin-right: 28px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-80 {
    margin-right: 80px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-14 {
    margin-right: 14px !important;
}

.display-block {
    display: block !important;
}

.ui.stackable.cards > .card {
    display: flex !important;
}

.smallLabel {
    padding-left: 10px;
    padding-right: 5px;
    color: rgba(0, 0, 0, 0.7);
}

.smallButton {
    padding-left: 5.25px !important;
    padding-right: 5.25px !important;
    padding-top: 5.25px !important;
    padding-bottom: 5.25px !important;
    font-size: 0.64285714rem !important;
}

.height-50 {
    height: 50px;
}

.list--header-inline {
    padding-right: 20px !important;
    display: inline-block !important;
}

.ui.form .required.field > label::after {
    font-size: 0.8em;
    content: '* (required)';
}

.small-avatar {
    width: 17px !important;
    height: 17px !important;
}

.micro-button {
    font-size: 10px !important;
    margin-left: 2px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right;
}

.text-red {
    color: #db2828 !important;
}

.text-gray {
    color: #676767 !important;
}

.text-black {
    color: #000000 !important;
}

.text-primary {
    color: #1678c2 !important;
}

.hard-wrap {
    word-wrap: anywhere;
}

.vertical-middle {
    vertical-align: middle !important;
}

.align-center  {
  align-items: center;
}

.selectionRadio--card {
    display: flex !important;
    align-items: center;
    cursor: pointer;
    i {
        margin-right: 10px;
    }
}

.study--header-sub {
    display: inline-block;
    margin-top: 0px;
    margin-right: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

/* Needed so that size does not change when showing success icon */
.CopyButton--popup {
    width: 4rem;
    text-align: center;
}

.science-bg {
    background-image: url(./assets/background-science.jpg) !important;
}

.study-header-background {
    background: #e8e8e899;
}

.View--Login {
    height: 100vh;
    background-image: linear-gradient(to bottom, #fff 400px, transparent 100%),
        url(./assets/background-science.jpg);
}

.app-banner-link a {
    margin-left: 1rem;
    font-weight: bolder;
    text-decoration: underline;
    color: white !important;
}
.app-banner-link a:hover {
    color: purple !important;
}
.max-h-500 {
    max-height: 500px;
    overflow: hidden;
}

.x-scroll {
    overflow-x: scroll;
}

.text-wrap-75 {
    max-width: 32em;
}

.progress-bar--custom {
    margin-top: 5px !important;
    div {
        text-align: left !important;
        font-weight: normal !important;
        color: grey !important;
    }
}

.footer {
    background: white !important;
    padding: 9px 0px !important;
    position: fixed !important;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    border-radius: 0px !important;
    box-shadow: none !important;
    border-width: 1px 0px 0px 0px !important;
}

.text-normal {
    font-weight: normal !important;
}

.text-10 {
    font-size: 10px !important;
}

.text-12 {
    font-size: 12px !important;
}

.text-14 {
    font-size: 14px !important;
}

.page {
    margin-bottom: 40px !important;
}

.text-grey {
    color: #919090 !important;
}

.margin-x-auto {
    margin-left: auto;
    margin-right: auto;
}

.text-center {
    text-align: center;
}

.noBefore {
    div:before {
        height: 0px !important;
    }
}

.width-fit-content {
    width: fit-content;
}

.text-blue {
    color: #4183c4 !important;
}

.text-green {
    color: #21ba45 !important;
}

.text-yellow {
    color: #fbbd08 !important;
}

.text-teal {
    color: #00b5ad!important;
}

.text-underline {
    text-decoration: underline;
}

.ui.dimmer .ui.loader:after {
    border-color: #767676 transparent transparent;
    border-style: solid;
    border-width: 0.2em;
}
.ui.dimmer .ui.loader:before {
    border: 0.2em solid rgba(0, 0, 0, 0.1);
}

.grey-container {
    border-radius: 8px !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.square-26 {
    height: 26px !important;
    width: 26px !important;
}

.scroll-container {
    overflow: scroll;
    max-height: 500px;
    min-height: 150px;
}

.h-500-container {
  overflow-y: scroll;
  height: 800px;
}

.h-38 {
    height: 38px !important;
}

.small-inline {
    display: inline-block;
    padding-top: 10px;
}

.display-inline {
    display: inline;
}

.display-block {
    display: block;
}

.overflow-cell-container {
    position: relative;
}

.no-border {
  input {
    border: none !important;
  }
}

.ui.form .fields > .field.expand,
.ui.form .fields > .input.expand {
    flex-grow: 1;
}

.overflow-cell {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.button.text-button,
.ui.button.text-button:hover,
.ui.button.text-button:active,
.ui.button.text-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
    text-decoration: underline;
    border-radius: 0;
    margin: 0;
    padding: 0;
}

.ui.inverted.dimmer > .content > * {
    color: #000;
}

.graphiql-container {
    height: 80vh !important;
}

code.label {
    background: #eee;
    padding: 2px;
}

.lh-inherit {
    line-height: inherit !important;
}

.bg-purple {
    background-color: #9627ba !important;
}

.ansi.terminal {
    max-height: 50em;
    overflow: scroll;
    line-height: 1.2em;
    background: #282c34;
    color: #eee;
    padding: 20px;
    margin: 0;
}

.ansi-black-fg {
    color: #abb2bf;
}
.ansi-red-fg {
    color: #e06c75;
}
.ansi-green-fg {
    color: #98c379;
}
.ansi-yellow-fg {
    color: #bb8a35;
}
.ansi-blue-fg {
    color: #61afef;
}
.ansi-magenta-fg {
}
.ansi-cyan-fg {
}
.ansi-white-fg {
    color: #9da5b4;
}
.ansi-bright-black-fg {
}
.ansi-bright-red-fg {
}
.ansi-bright-green-fg {
}
.ansi-bright-yellow-fg {
}
.ansi-bright-blue-fg {
}
.ansi-bright-magenta-fg {
}
.ansi-bright-cyan-fg {
}
.ansi-bright-white-fg {
}
.ansi-black-bg {
}
.ansi-red-bg {
}
.ansi-green-bg {
}
.ansi-yellow-bg {
}
.ansi-blue-bg {
}
.ansi-magenta-bg {
}
.ansi-cyan-bg {
}
.ansi-white-bg {
}

.rst__moveHandle,
.rst__rowContents {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding-left: 0px !important;
}

.rst__node {
  width: 50%;
  white-space: nowrap;
  position: relative;
  text-align: left;
  min-width: 100%;
  overflow: hidden;
}

.rst__nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80%;
}

.image-input {
  div {
    i::before {
      content: "" !important;;
    }
    i {
      margin-left: 5px !important;
      margin-top: 5px !important;
    }
  }
}
